import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { Router, createRouter } from '@tanstack/react-router';
import { registerSW } from 'virtual:pwa-register';

import { routeTree } from './routeTree.gen.ts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';
const router: Router = createRouter({ routeTree });

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: 'https://c221fa94193675d5413103fb209c1be8@o4507903940624384.ingest.us.sentry.io/4507904006815744',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  // Tracing
  tracesSampleRate: 0.6,
  tracePropagationTargets: [
    'http://localhost:3000',
    'https://beta.api.ciboeats.in', // Beta API URL
    'https://api.ciboeats.in', // Production API URL
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.MODE !== 'development',
});
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// add this to prompt for a refresh
const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm('New content available. Reload?')) {
      updateSW(true);
    }
  },
});

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const queryClient = new QueryClient();
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-center" reverseOrder={false} />
        <App router={router} />
      </QueryClientProvider>
    </StrictMode>,
  );
}
