import React, { useEffect, useRef, useState } from 'react';
import StarRating from './StarRating';
import { CartInterface, DishInterface, FoodType, IngredientType, RatingType, SizeType } from '../Interface';
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord, isEmpty } from '../utils/common';
import FoodTypeIndicator from './FoodTypeIndicator';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
// import Drawer from './Drawer';
import { TOTAL_STAR_COUNT } from '../constants/common';
import Checkbox from './CheckBox';
import RadioButton from './RadioButton';
import { useCartStore } from '../store/cartStore';
import { useAuthStore } from '../store/authStore';
import FallbackDishSvg from '../assets/fallback-dish.svg?react';
import clsx from 'clsx';
import { useRouter } from '@tanstack/react-router';
import Button from './Button';
import CustomDrawerV2 from './CustomDrawerV2';

export function ItemCounter({
  count,
  addItem,
  removeItem,
  disabled = false,
  addDisabled = false,
}: {
  count: number;
  addItem: () => void;
  removeItem: () => void;
  disabled?: boolean;
  addDisabled?: boolean;
}) {
  return (
    <div className="flex border bg-white rounded-md px-1 py-1 justify-between items-center gap-2">
      <button className="" onClick={removeItem} disabled={disabled}>
        <div>
          <IoIosRemoveCircle
            className={clsx('text-2xl ', {
              'text-gold_start': !disabled,
              'text-gray-300': disabled,
            })}
          />
        </div>
      </button>
      <p>{count}</p>
      <button className="" onClick={addItem} disabled={disabled || addDisabled}>
        <div>
          <IoIosAddCircle
            className={clsx('text-2xl ', {
              'text-gold_start': !(disabled || addDisabled),
              'text-gray-300': disabled || addDisabled,
            })}
          />
        </div>
      </button>
    </div>
  );
}
function Dish({
  dish,
  tableId,
  isAuthenticated,
  differentRestarant = false,
}: {
  dish: DishInterface;
  tableId?: string | null;
  isAuthenticated: boolean;
  differentRestarant?: boolean;
}) {
  const letterLimit = 40;
  const router = useRouter();
  const { cart, addToCart, updateCart, cartLoading } = useCartStore((state) => ({
    cart: state.cart,
    addToCart: state.addToCart,
    updateCart: state.updateCart,
    cartLoading: state.cartLoading,
  }));

  const [cartItems, setCartItems] = useState<CartInterface[]>(cart.filter((item) => item.dishId === dish._id));
  const [initialCartItems, setInitialCartItems] = useState<CartInterface[]>([]);
  const [cartChanged, setCartChanged] = useState(false);
  const refCartItems = useRef<CartInterface[]>(cart.filter((item) => item.dishId === dish._id));
  const { userName } = useAuthStore((state) => ({
    userName: state.userName,
  }));
  const [count, setCount] = useState(
    cart.reduce((acc, item) => acc + (item.dishId === dish._id ? item.quantity : 0), 0),
  );

  const [addEnabled, setAddEnabled] = useState(true);
  useEffect(() => {
    if (tableId === null && !isAuthenticated) {
      setAddEnabled(false);
    }
  }, [tableId, isAuthenticated]);

  const [cartCount, setCartCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showAddedOptions, setShowAddedOptions] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const customisationOptionAvailable =
    dish.customisations &&
    ((dish.customisations.sizes && dish.customisations.sizes.length !== 0) ||
      (dish.customisations.ingredients && dish.customisations.ingredients.length !== 0));

  const [radioSelected, setRadioSelected] = useState<SizeType>(
    dish.customisations && dish.customisations.sizes && dish.customisations.sizes[0]
      ? {
          size: dish.customisations.sizes[0].size,
          price: dish.customisations.sizes[0].price,
          sizeId: dish.customisations.sizes[0]._id,
        }
      : {
          size: '',
          price: 0,
          sizeId: '',
        },
  );
  const [checkboxSelected, setCheckboxSelected] = useState<Array<IngredientType>>([]);
  const [totalPrice, setTotalPrice] = useState(dish.price);

  useEffect(() => {
    let price = dish.price;
    if (dish.customisations && dish.customisations.sizes && dish.customisations.sizes.length !== 0) {
      price = dish.customisations.sizes.find((item) => item.size === radioSelected.size)?.price || dish.price;
      setTotalPrice(price);
    }
    if (dish.customisations && dish.customisations.ingredients && dish.customisations.ingredients.length !== 0) {
      price += dish.customisations.ingredients.reduce((acc, item) => {
        if (checkboxSelected.some((selected) => selected.ingredient === item.ingredient)) {
          acc += item.extraPrice;
        }
        return acc;
      }, 0);
      setTotalPrice(price);
    }
  }, [radioSelected, checkboxSelected]);

  useEffect(() => {
    setCount(cart.reduce((acc, item) => acc + (item.dishId === dish._id ? item.quantity : 0), 0));
    const tempCart = cart.filter((item) => item.dishId === dish._id);
    setCartItems(tempCart);
    refCartItems.current = tempCart;
    setInitialCartItems(JSON.parse(JSON.stringify(tempCart)));
  }, [cart]);

  useEffect(() => {
    const isChanged = cartItems.some((item, index) => {
      return item.quantity !== initialCartItems[index]?.quantity;
    });
    setCartChanged(isChanged);
  }, [cartItems, initialCartItems]);

  function addToCartHandler(quantity: number, loading: boolean = true) {
    const customisations = {
      ...(radioSelected.size !== '' && radioSelected.price !== 0 && { size: radioSelected }),
      ...(checkboxSelected.length !== 0 && { ingredients: checkboxSelected }),
    };
    if (!userName) return; // error modal
    const dishBody = {
      userName: userName,
      dishId: dish._id,
      dishName: dish.name,
      quantity: quantity,
      price: totalPrice,
      type: dish.dishType,
      uuid: '',
      ...(!isEmpty(customisations) && { customisations }),
    };
    addToCart(dishBody, loading);
  }

  function updateCartHandler(loading: boolean = true) {
    const dishes: Array<{
      uuid: string;
      quantity: number;
    }> = [];
    let changes = false;

    cartItems.forEach((item, index) => {
      const existingQuantity = refCartItems.current[index].quantity;
      if (existingQuantity !== item.quantity) {
        changes = true;
        dishes.push({
          uuid: item.uuid,
          quantity: item.quantity - existingQuantity,
        });
      }
    });
    if (changes) updateCart(dishes, loading);
  }

  return (
    <div>
      <div
        className={clsx('flex rounded-xl  p-3 gap-2', {
          'pointer-events-none z-[-5]': !dish.availability || !dish.timeAvailability,
        })}
      >
        <div className="basis-2/5 flex flex-col">
          <div className="border-[1px] rounded-xl flex flex-col items-center">
            {imageError || dish.imageUrl === null ? (
              <FallbackDishSvg className="h-32" />
            ) : (
              <img
                src={dish.imageUrl}
                className={clsx('rounded-xl w-full h-32 object-cover', {
                  'filter grayscale z-[-10]': !dish.availability || !dish.timeAvailability,
                })}
                alt="Dish"
                onError={() => setImageError(true)}
              />
            )}
          </div>

          <div className="flex flex-col items-center mt-[-15px] w-full px-4">
            {addEnabled &&
              !differentRestarant &&
              (count === 0 ? (
                <button
                  className={clsx('w-full px-4 bg-white border-[1px] rounded-md py-1', {
                    'font-thin text-gray-500': !(dish.availability && dish.timeAvailability),
                    'text-primary font-medium': dish.availability && dish.timeAvailability,
                  })}
                  onClick={() => {
                    if (isAuthenticated) {
                      if (customisationOptionAvailable) {
                        setOpen(true);
                      } else {
                        addToCartHandler(1);
                      }
                    } else if (tableId !== null && tableId?.length !== 0 && !isAuthenticated) {
                      router.navigate({
                        to: `/register/${tableId}`,
                        replace: true, // Optional: Replaces current entry in history
                      });
                    }
                  }}
                >
                  {dish.availability && dish.timeAvailability ? 'Add' : 'Unavailable'}
                </button>
              ) : (
                <ItemCounter
                  removeItem={() => {
                    setShowAddedOptions(true);
                  }}
                  count={count}
                  addItem={() => {
                    if (customisationOptionAvailable) {
                      setShowAddedOptions(true);
                    } else {
                      addToCartHandler(1);
                    }
                  }}
                />
              ))}
            {dish.customisations && <p className="text-xs pt-0.5 text-gray-400">Customisable</p>}
          </div>
        </div>
        <div className="flex flex-col basis-3/5 overflow-hidden">
          <FoodTypeIndicator type={dish.dishType === 'veg' ? FoodType.VEG : FoodType.NON_VEG} />
          <p className="font-semibold line-clamp-2">{capitalizeFirstLetterOfEachWord(dish.name)}</p>
          <p className="text-black_1 font-semibold pt-1">&#8377;{dish.price}</p>
          <StarRating
            rating={dish.rating}
            totalStars={TOTAL_STAR_COUNT}
            totalRatings={69}
            type={RatingType.SECONDARY}
          />
          <p className={clsx('text-sm text-gray-400 line-clamp-2 pt-1', { 'line-clamp-none': viewMore })}>
            {dish.description}
          </p>
          {dish.description.length > letterLimit && !viewMore && (
            <span
              className="text-sm font-semibold hover:cursor-pointer transition-all"
              onClick={() => {
                setViewMore(true);
              }}
            >
              more
            </span>
          )}
        </div>

        <EditCustomisation
          customisationOptionAvailable={customisationOptionAvailable}
          dish={dish}
          addEnabled={addEnabled}
          setOpen={setOpen}
          cartItems={cartItems}
          userName={userName}
          setCartItems={setCartItems}
          setShowAddedOptions={setShowAddedOptions}
          showAddedOptions={showAddedOptions}
          updateCartHandler={updateCartHandler}
          cartChanged={cartChanged}
        />
        <SelectCustomisation
          addToCartHandler={addToCartHandler}
          dish={dish}
          radioSelected={radioSelected}
          setRadioSelected={setRadioSelected}
          checkboxSelected={checkboxSelected}
          setCheckboxSelected={setCheckboxSelected}
          setCartCount={setCartCount}
          cartCount={cartCount}
          addEnabled={addEnabled}
          setOpen={setOpen}
          open={open}
          totalPrice={totalPrice}
        />
      </div>
      <hr />
    </div>
  );
}

const EditCustomisation = ({
  customisationOptionAvailable,
  dish,
  addEnabled,
  setOpen,
  cartItems,
  userName,
  setCartItems,
  setShowAddedOptions,
  updateCartHandler,
  showAddedOptions,
  cartChanged,
}: {
  customisationOptionAvailable: boolean | null;
  dish: DishInterface;
  addEnabled: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cartItems: CartInterface[];
  userName: string | null;
  setCartItems: React.Dispatch<React.SetStateAction<CartInterface[]>>;
  setShowAddedOptions: React.Dispatch<React.SetStateAction<boolean>>;
  showAddedOptions: boolean;
  updateCartHandler: (loading?: boolean) => void;
  cartChanged: boolean;
}) => {
  useEffect(() => {
    if (showAddedOptions) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showAddedOptions]);

  return (
    <CustomDrawerV2 open={showAddedOptions} setOpen={setShowAddedOptions}>
      <>
        <p className="text-app_grey">{dish.name}</p>
        <p className="text-xl font-semibold">Your customisations</p>
        <hr className="my-3" />
        <div className="flex flex-col gap-3">
          {cartItems.map((item, index) => (
            <div
              className="relative flex justify-between items-center gap-2 w-full rounded-lg bg-white p-3"
              key={index}
            >
              <div className="w-[65%]">
                <div className="flex gap-2 justify-start items-center">
                  <FoodTypeIndicator type={item.type === 'veg' ? FoodType.VEG : FoodType.NON_VEG} />

                  <p className="text-sm">
                    Added by{' '}
                    <span className="font-medium">
                      {capitalizeFirstLetter(item.userName)}
                      {userName === item.userName && ' (You)'}
                    </span>
                  </p>
                  {userName !== item.userName && <span className="text-sm">Qty. {item.quantity}</span>}
                </div>
                {item.customisations?.size && (
                  <>
                    <p className="font-medium mt-2">Size</p>
                    <div className="flex gap-3 justify-between items-center w-full overflow-hidden">
                      <p className="whitespace-nowrap text-ellipsis overflow-hidden">{item.customisations.size.size}</p>
                      <p>&#8377;{item.customisations.size.price}</p>
                    </div>
                    <hr className="mt-1" />
                  </>
                )}
                {item.customisations?.ingredients && (
                  <div className="flex flex-col">
                    <p className="font-medium mt-2">Add-ons</p>
                    {item.customisations.ingredients.map((ingredient, index) => (
                      <div key={index} className="flex gap-3 justify-between items-center w-full overflow-hidden">
                        <p className="whitespace-nowrap text-ellipsis overflow-hidden">{ingredient.ingredient}</p>
                        <p>+&#8377;{ingredient.extraPrice}</p>
                      </div>
                    ))}
                    <hr className="mt-1" />
                  </div>
                )}
                <p
                  className={clsx('mt-2', {
                    'text-right': customisationOptionAvailable,
                    'text-left': !customisationOptionAvailable,
                  })}
                >
                  &#8377;{item.price}
                </p>
              </div>

              <ItemCounter
                addDisabled={userName !== item.userName}
                removeItem={() => {
                  if (item.quantity >= 1) {
                    setCartItems((prev) =>
                      prev.map((item, i) => (i === index ? { ...item, quantity: item.quantity - 1 } : item)),
                    );
                  }
                }}
                count={item.quantity}
                addItem={() => {
                  setCartItems((prev) =>
                    prev.map((item, i) => (i === index ? { ...item, quantity: item.quantity + 1 } : item)),
                  );
                }}
              />
            </div>
          ))}
          {addEnabled && (
            <div className="relative flex justify-between items-center gap-2 w-full rounded-lg bg-white p-3 mb-16">
              <motion.button
                className="w-full max-w-72"
                whileTap={{ scale: 0.97 }}
                onClick={() => {
                  setShowAddedOptions(false);
                  setOpen(true);
                }}
              >
                <motion.div className="rounded-md px-5 py-2 text-black font-medium" transition={{ duration: 0.2 }}>
                  Add New Customisation
                </motion.div>
              </motion.button>
            </div>
          )}
          {addEnabled && (
            <div className="w-full fixed bottom-2 left-0 mx-auto">
              <Button
                type="primary"
                disabled={!cartChanged}
                className={clsx('!w-[90%] max-w-[500px] rounded-md px-5 py-2 bg-primary text-white font-medium')}
                onClick={() => {
                  setShowAddedOptions(false);
                  updateCartHandler(false);
                }}
                title="Update Cart"
              />
            </div>
          )}
        </div>
      </>
    </CustomDrawerV2>
  );
};

const SelectCustomisation = ({
  addToCartHandler,
  dish,
  radioSelected,
  setRadioSelected,
  checkboxSelected,
  setCheckboxSelected,
  setCartCount,
  cartCount,
  addEnabled,
  setOpen,
  open,
  totalPrice,
}: {
  addToCartHandler: (quantity: number, loading?: boolean) => void;
  dish: DishInterface;
  radioSelected: SizeType;
  setRadioSelected: React.Dispatch<React.SetStateAction<SizeType>>;
  checkboxSelected: Array<IngredientType>;
  setCheckboxSelected: React.Dispatch<React.SetStateAction<Array<IngredientType>>>;
  setCartCount: React.Dispatch<React.SetStateAction<number>>;
  cartCount: number;
  addEnabled: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  totalPrice: number;
}) => {
  return (
    <CustomDrawerV2 open={open} setOpen={setOpen}>
      <>
        <p className="text-app_grey">{dish.name}</p>
        <p className="text-xl font-semibold">Choose your customisations</p>
        <hr className="my-3" />

        {/* Sizes Section */}
        {dish.customisations && dish.customisations.sizes && dish.customisations.sizes.length !== 0 && (
          <>
            <p className="text-lg font-semibold mb-1">Size</p>
            <div className="w-full rounded-lg bg-white p-3">
              {dish.customisations.sizes.map((item, index) => (
                <div className="w-full flex gap-2 justify-between items-center" key={index}>
                  <p>{item.size}</p>
                  <div className="flex gap-2">
                    <p>&#8377;{item.price}</p>
                    <RadioButton
                      name={item.size}
                      value={item.price}
                      checked={radioSelected.size === item.size}
                      onChange={() => {
                        setRadioSelected({
                          size: item.size,
                          price: item.price,
                          sizeId: item._id,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Ingredients (Add-ons) Section */}
        {dish.customisations && dish.customisations.ingredients && dish.customisations.ingredients.length !== 0 && (
          <>
            <p className="text-lg font-semibold mb-1 mt-3">Add-ons</p>
            <div className="w-full rounded-lg bg-white p-3">
              {dish.customisations.ingredients.map((item, index) => (
                <div className="w-full flex gap-2 justify-between items-center" key={index}>
                  <div className="flex gap-2 justify-center items-center">
                    <FoodTypeIndicator type={item.type === 'veg' ? FoodType.VEG : FoodType.NON_VEG} />
                    <p>{item.ingredient}</p>
                  </div>
                  <div className="flex gap-2">
                    <p>+&#8377;{item.extraPrice}</p>
                    <Checkbox
                      checked={checkboxSelected.some((selected) => selected.ingredient === item.ingredient)}
                      onChange={() => {
                        if (checkboxSelected.some((selected) => selected.ingredient === item.ingredient)) {
                          setCheckboxSelected((prev) =>
                            prev.filter((selected) => selected.ingredient !== item.ingredient),
                          );
                        } else {
                          setCheckboxSelected((prev) => [
                            ...prev,
                            {
                              ingredient: item.ingredient,
                              extraPrice: item.extraPrice,
                              ingredientId: item._id,
                            },
                          ]);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Add to Cart Button */}
        <div className="fixed bottom-0 py-3 bg-white w-full px-3 left-0 shadow-addItem flex justify-between items-center gap-6">
          <ItemCounter
            removeItem={() => setCartCount((prev) => prev - 1)}
            count={cartCount}
            addItem={() => setCartCount((prev) => prev + 1)}
          />
          {addEnabled && (
            <motion.button
              className="w-full max-w-72"
              whileTap={{ scale: 0.97 }}
              onClick={() => {
                setOpen(false);
                addToCartHandler(cartCount, false);
              }}
            >
              <motion.div className="bg-primary rounded-md px-5 py-2 text-white" transition={{ duration: 0.2 }}>
                Add | &#8377;{totalPrice * cartCount}
              </motion.div>
            </motion.button>
          )}
        </div>
      </>
    </CustomDrawerV2>
  );
};

export default Dish;
