import { motion, AnimatePresence } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect } from 'react';

const CustomDrawerV2 = ({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <div
          className="fixed inset-0 bg-black/40 flex items-end justify-center z-50"
          onClick={() => setOpen(false)} // Close on outside click
        >
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', stiffness: 250, damping: 30 }}
            className="bg-zinc-100 flex flex-col rounded-t-lg w-full max-w-full h-[70%] mt-24 relative"
            onClick={(e) => e.stopPropagation()} // Prevent click inside the drawer from closing
          >
            <button
              className="absolute top-4 right-4 text-gray-500 shadow-md bg-white font-bold w-6 h-6 rounded-full"
              onClick={() => setOpen(false)}
            >
              &#x2715;
            </button>
            <div className="flex flex-col px-4 py-2 h-full gap-4">
              <div className="overflow-auto flex-1 pr-2 custom-scrollbar mt-6">
                {children} {/* Render passed children here */}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default CustomDrawerV2;
