import React from 'react';
import toast, { ToastOptions } from 'react-hot-toast';
import { IoClose } from 'react-icons/io5';
import { FcInfo } from 'react-icons/fc';
import { PiWarningFill } from 'react-icons/pi';

export function HotToast({
  message,
  type = 'custom',
  options = {},
}: {
  message: string;
  type?: 'success' | 'error' | 'custom' | 'loading' | 'info' | 'warning';
  options?: ToastOptions;
}) {
  const toastOptions = {
    ...options,
    duration: 3000,
    style: {
      ...options.style,
    },
  };

  switch (type) {
    case 'success':
      toast.success(
        (t) => (
          <div className="flex gap-2 justify-center items-center">
            <p>{message}</p>
            <button onClick={() => toast.dismiss(t.id)}>
              <IoClose className="h-6 w-6 text-slate-600" />
            </button>
          </div>
        ),
        toastOptions,
      );
      break;
    case 'error':
      toast.error(
        (t) => (
          <div className="flex gap-2 justify-center items-center">
            <p>{message}</p>
            <button onClick={() => toast.dismiss(t.id)}>
              <IoClose className="h-6 w-6 text-slate-600" />
            </button>
          </div>
        ),
        toastOptions,
      );
      break;
    case 'loading':
      toast.loading(
        (t) => (
          <div className="flex gap-2 justify-center items-center">
            <p>{message}</p>
            <button onClick={() => toast.dismiss(t.id)}>
              <IoClose className="h-6 w-6 text-slate-600" />
            </button>
          </div>
        ),
        toastOptions,
      );
      break;
    case 'info':
      options = {
        ...toastOptions,
        icon: <FcInfo />,
      };
      toast(
        (t) => (
          <div className="flex gap-2 justify-center items-center">
            <p>{message}</p>
            <button onClick={() => toast.dismiss(t.id)}>
              <IoClose className="h-6 w-6 text-slate-600" />
            </button>
          </div>
        ),
        options,
      );
      break;
    case 'warning':
      options = {
        ...toastOptions,
        icon: <PiWarningFill />,
      };
      toast(
        (t) => (
          <div className="flex gap-2 justify-center items-center">
            <p>{message}</p>
            <button onClick={() => toast.dismiss(t.id)}>
              <IoClose className="h-6 w-6 text-slate-600" />
            </button>
          </div>
        ),
        options,
      );
      break;
    default:
      toast(message, toastOptions);
      break;
  }
}
