import { redirect, Router, RouterProvider } from '@tanstack/react-router';
import { useCartStore } from './store/cartStore';
import { useEffect } from 'react';
import { useAuthStore } from './store/authStore';
import { HotToast } from './components/HotToast';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

function App({ router }: { router: Router }) {
  const { socket, initializeSocket, joinSession, isSocketConnected } = useCartStore((state) => ({
    socket: state.socket,
    initializeSocket: state.initializeSocket,
    joinSession: state.joinSession,
    isSocketConnected: state.isSocketConnected,
  }));

  const { authToken, userName, fingerPrint, setFingerprint } = useAuthStore((state) => ({
    authToken: state.authToken,
    userName: state.userName,
    fingerPrint: state.fingerPrint,
    setFingerprint: state.setFingerPrint,
  }));

  const handleReconnect = () => {
    try {
      console.log('Network is back online, reconnecting WebSocket...');
      if (!socket) {
        initializeSocket();
      }
      if (userName && !isSocketConnected) {
        joinSession(userName);
      }
    } catch (e) {
      console.error('Error in reconnecting websocket:', e);
      HotToast({ message: 'Please Refresh the page to join the party!', type: 'error' });
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      console.log('Page is visible, reconnecting WebSocket if needed');
      if (!socket) {
        initializeSocket();
      }
      if (userName && !isSocketConnected) {
        joinSession(userName);
      }
    }
  };

  useEffect(() => {
    if (!fingerPrint)
      getFingerprint()
        .then((result) => {
          const fingerPrint = typeof result === 'string' ? result : result.hash;
          setFingerprint(fingerPrint);
        })
        .catch((error) => {
          console.error('Error getting fingerprint:', error);
        });
  }, []);

  useEffect(() => {
    // Add event listeners for network and visibility changes
    window.addEventListener('online', handleReconnect);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleReconnect);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isSocketConnected, socket, userName]);

  useEffect(() => {
    if ((userName && !socket) || (userName && authToken && !isSocketConnected)) {
      const cleanup = initializeSocket();
      try {
        joinSession(userName);
      } catch (e) {
        HotToast({ message: 'Error in joining session. You will be redirected in 5 seconds', type: 'error' });
        setTimeout(() => {
          redirect({
            to: '/',
            replace: true,
          });
        }, 5000);
      }
      return () => {
        cleanup();
      };
    }
  }, [authToken, userName]);
  return <RouterProvider router={router} />;
}

export default App;
