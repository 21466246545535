import Lottie from 'lottie-react';
import tableClosed from '../assets/animations/table_closed.json';
import { Link, createFileRoute, useRouter } from '@tanstack/react-router';
import Button from '../components/Button';

function SessionNotFound() {
  const router = useRouter();
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen p-4">
      <p className="text-2xl pb-2">Sorry!</p>
      <Lottie className="w-auto h-[20vh]" animationData={tableClosed} loop={true} />
      <p className="text-xl pt-4">Table has been closed</p>
      <p className="text-xl pb-4">by the restaurant</p>
      <div className = "mt-5 flex flex-col items-center gap-2">
        <Button type="primary" title="View Bill" onClick={() => {
          router.navigate({
            to: `/bill/${sessionId}`,
          });
        }} />
        <p>or</p>
        <Link to="/" className="text-primary underline font-medium">
          Go back to home
        </Link>
      </div>
    </div>
  );
}

export const Route = createFileRoute('/sessionNotFound')({
  component: SessionNotFound,
});

export default SessionNotFound;
