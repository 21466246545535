import React, { ChangeEvent, useState } from 'react';
import Button from './Button';
import Input from './Input';
import Otpinput from './Otpinput';
import useAxios from '../hooks/useAxios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { OTP_DIGITS } from '../constants/common';
import { usePersistState } from '../hooks/usePersistState';
import { PersistentStateEnum, TableStatusEnum } from '../constants/enum';
import { useAuthStore } from '../store/authStore';
import { Link, redirect, useNavigate } from '@tanstack/react-router';
import { HotToast } from './HotToast';
import { parseAxiosError } from '../utils/common';
import { AxiosError } from 'axios';

const UserRegister = ({
  tableId,
  restaurantName,
  tableName,
  tablePIN,
  userName,
  userInitiated,
}: {
  tableId: string;
  restaurantName: string;
  tableName: string;
  tablePIN: string | undefined;
  userName: string | undefined;
  userInitiated: boolean;

}) => {
  const { postWithoutAuth } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);

  const { setAuthToken, setRefreshToken, setIdToken, setUserName, setSessionID, setTablePIN, getRestaurantId } =
    useAuthStore((state) => ({
      setAuthToken: state.setAuthToken,
      setRefreshToken: state.setRefreshToken,
      setIdToken: state.setIdToken,
      setUserName: state.setUserName,
      setSessionID: state.setSessionID,
      setTablePIN: state.setTablePIN,
      getRestaurantId: state.getRestaurantId,
    }));
  const navigate = useNavigate({ from: '/register/$tableId' });

  const validationSchema = yup.object({
    userName: yup
      .string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .matches(/^[a-zA-Z0-9.]+$/, 'Must not contain special characters'),
    pin: yup
      .string()
      .test('max digits', 'Must be only 4 digits', (value) => value?.length === 4)
      .required('Table PIN is required'),
  });

  const userFormik = useFormik({
    initialValues: {
      userName: userName || '',
      pin: tablePIN || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await postWithoutAuth(`/app/auth-customer/${tableId}/add-user-to-session`, {
          userName: values.userName,
          pin: values.pin.toString(),
        });

        setAuthToken(response.token);
        setUserName(response.userName);
        setTablePIN(values.pin);
        setSessionID(response.sessionId);
        navigate({
          to: '/order',
          replace: true,
        });
      } catch (e) {
        let message;
        if (e instanceof AxiosError) {
          message = parseAxiosError(e);
          message = message.errorMessage;
        } else if (e instanceof Error) {
          message = e.message;
        } else {
          message = 'Unknown error occurred, please try again later';
        }
        HotToast({ message: message, type: 'error' });
      }
      setLoading(false);
    },
  });

  const handlePinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Replace all non-numeric characters with an empty string
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    userFormik.setFieldValue('pin', sanitizedValue);
  };
  return (
    <div className="w-full h-full rounded-2xl flex flex-col justify-center items-center">
      <div className="w-full flex flex-col justify-around items-center gap-2 h-full px-4 my-10">
        <div className="flex flex-col justify-between items-center gap-0 w-full">
          <p className="text-2xl font-medium mb-1 text-center">{restaurantName}</p>
          <p className="text-base font-normal mb-4">Table: {tableName}</p>
          
        </div>
        <div className="flex flex-col justify-between items-center gap-4 w-full">
        {userInitiated === false && <p className = "text-primary">*Ask the Restaurant for the pin!</p>}
          <div className="w-full">
            <Input
              placeholder="Name"
              type="text"
              name="userName"
              value={userFormik.values.userName}
              onChange={userFormik.handleChange}
            />
            {userFormik.touched.userName && userFormik.errors.userName ? (
              <div className="mt-1 px-2 text-red-500 text-sm w-full text-left">{userFormik.errors.userName}</div>
            ) : null}
          </div>
          <div className="w-full mb-2">
            <Input
              placeholder="Table PIN"
              type="text"
              name="pin"
              value={userFormik.values.pin}
              onChange={handlePinChange}
              inputMode="numeric"
            />
            {userFormik.touched.pin && userFormik.errors.pin ? (
              <div className="mt-1 px-2 text-red-500 text-sm w-full text-left">{userFormik.errors.pin}</div>
            ) : null}
          </div>
        </div>

        <Button title="Join Table" type="primary" isLoading={loading} onClick={userFormik.handleSubmit} />
        <Link to={`/restaurants/${getRestaurantId()}`} className="text-primary underline font-medium">
          View menu
        </Link>
      </div>
    </div>
  );
};

export default UserRegister;
